/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Button,
  Drawer,
  Fieldset,
  Input,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  TextInput,
} from "@mantine/core";
import { ChevronRight, PlusCircle, Search } from "react-feather";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API } from "../../static";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LeavesTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [leaves, setLeaves] = useState();
  const [editLeaveModalOpened, editLeaveModalHandlers] = useDisclosure(false);
  const [selectedLeave, setSelectedLeave] = useState();
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState("Pending");
  const [filteredData, setFilteredData] = useState();

  const getLeaves = () => {
    return useQuery(
      "getLeaves",
      async () => {
        return await axios.get(`${API}/admin-api/V1/leave/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setLeaves(data.data);
        },
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 60,
      }
    );
  };

  const { isLoading } = getLeaves();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Search should be case-insensitive
  };

  const filterData = (data) => {
    return data?.filter((row) => {
      const searchTerm = searchQuery.toLowerCase();
      const firstName = row.employee.firstName.toLowerCase();
      const lastName = row.employee.lastName.toLowerCase();
      const phone = row.employee.phoneNumber.toLowerCase();
      const email = row.employee.email.toLowerCase();
      const role = row.employee.role.toLowerCase();
      return (
        row.status === selectedLeaveStatus &&
        (firstName.includes(searchTerm) ||
          lastName.includes(searchTerm) ||
          phone.includes(searchTerm) ||
          role.includes(searchTerm) ||
          email.includes(searchTerm))
      );
    });
  };

  useEffect(() => {
    if (leaves) {
      setFilteredData(filterData(leaves));
    }
  }, [searchQuery, leaves, selectedLeaveStatus]);

  const columns = [
    {
      name: t("FullName"),
      selector: (row) => (
        <div>
          <h6 className="m-0">{`${row.employee.firstName} ${row.employee.lastName}`}</h6>
          <span>{`${row.employee.role}`}</span>
        </div>
      ),
      width: "200px",
    },
    {
      name: t("Period"),
      selector: (row) =>
        `From ${moment(row.startDate).format("DD MMMM")} to ${moment(
          row.endDate
        ).format("DD MMMM")}`,
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "Approved" ? (
          <Badge color="green">Approved</Badge>
        ) : row.status === "Pending" ? (
          <Badge color="orange">Pending</Badge>
        ) : (
          <Badge color="red">Refused</Badge>
        ),
    },
    {
      name: t("Number of days"),
      selector: (row) => row.totalDays + " days",
    },
    {
      name: t("Creation Date"),
      selector: (row) =>
        moment(row.createdAt ?? row.startDate).format("DD MMMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <Button
          onClick={() => {
            setSelectedLeave(row);
            editLeaveModalHandlers.open();
          }}
          variant="outline"
          rightSection={<ChevronRight size={15} />}
        >
          Details
        </Button>
      ),
    },
  ];

  const updateLeaveStatus = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/leave/update-status/${selectedLeave?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getLeaves");
      toast.success(t("SavedSuccessfully"));
      editLeaveModalHandlers.close();
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleEditStatus = (newStatus) => {
    updateLeaveStatus.mutate({
      status: newStatus,
    });
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Input
          leftSection={<Search size={16} />}
          placeholder={t("SearchPlaceholder")}
          className="w-50"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <SegmentedControl
          data={["Pending", "Approved", "Refused"]}
          value={selectedLeaveStatus}
          onChange={(val) => setSelectedLeaveStatus(val)}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        direction="auto"
        fixedHeaderScrollHeight="300px"
        highlightOnHover
        pagination
        responsive
        striped
        subHeaderAlign="right"
        subHeaderWrap
      />
      <Drawer
        opened={editLeaveModalOpened}
        onClose={editLeaveModalHandlers.close}
        title={"Leave details"}
        position="right"
        withCloseButton
      >
        <LoadingOverlay visible={updateLeaveStatus.isLoading} />
        <Fieldset legend="Employee Info">
          <div className="d-flex justify-content-between align-items-center">
            <p className="fw-bold">Employee ID</p>
            <p>{selectedLeave?.employee.employeeId}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Full name</p>
            <p>
              {selectedLeave?.employee.firstName}{" "}
              {selectedLeave?.employee.lastName}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Employee since</p>
            <p>
              {moment(selectedLeave?.employee.employmentDate).format(
                "DD MMMM YYYY"
              )}
            </p>
          </div>

          <Button
            className="mt-3"
            variant="outline"
            fullWidth
            onClick={() =>
              window.open(`/employee/details/${selectedLeave?.employee?._id}`)
            }
          >
            Full employee info
          </Button>
        </Fieldset>
        <Fieldset legend="Leave Info" className="mt-3">
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Leave type</p>
            <p>{selectedLeave?.leaveType?.englishName}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Type</p>
            <Badge>{selectedLeave?.leaveType?.type}</Badge>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Remaining days</p>
            <p>
              {
                selectedLeave?.employee.assignedLeaveTypes.find(
                  (el) => el.leaveType === selectedLeave?.leaveType._id
                ).creditDays
              }{" "}
              days remaining
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Start date</p>
            <p>{moment(selectedLeave?.startDate).format("DD MMMM YYYY")}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">End date</p>
            <p>{moment(selectedLeave?.endDate).format("DD MMMM YYYY")}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Total days</p>
            <p>{selectedLeave?.totalDays}</p>
          </div>
          {selectedLeave?.status !== "Pending" && <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="fw-bold">Update date and time</p>
            <p>{moment(selectedLeave?.updatedAt).format("DD MMMM YYYY HH:mm")}</p>
          </div>}
        </Fieldset>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button variant="outline" color="grey" onClick={editLeaveModalHandlers.close}>
            Cancel
          </Button>
          {selectedLeave?.status === "Pending" && <div className="d-flex">
            <Button
              color="red"
              className="mx-1"
              onClick={() => handleEditStatus("Refused")}
            >
              Refuse
            </Button>
            <Button onClick={() => handleEditStatus("Approved")}>Accept</Button>
          </div>}
        </div>
      </Drawer>
    </>
  );
};

export default LeavesTable;
