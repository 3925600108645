/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { H6 } from "../../AbstractElements";
import { useRecoilValue } from "recoil";
import { employeeDataAtom } from "../../recoil/atoms";
import moment from "moment";
import { Badge, Button, LoadingOverlay, Modal } from "@mantine/core";
import { PlusCircle } from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API, COLORS } from "../../static";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const SchedulesTab = () => {
  const employeeData = useRecoilValue(employeeDataAtom);
  const [modalOpened, modalHandlers] = useDisclosure(false);

  const [schedules, setSchedules] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState();

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const getSchedules = () => {
    return useQuery(
      "getSchedules",
      async () => {
        return await axios.get(`${API}/admin-api/V1/schedule/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setSchedules(data.data);
        },
      }
    );
  };

  const { isLoading } = getSchedules();

  const updateAssignedScheduleMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/employee/edit-active-schedule`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployee");
      toast.success(t("SavedSuccessfully"));
      modalHandlers.close();
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSave = () => {
    updateAssignedScheduleMutation.mutate({
      employeeId: employeeData?._id,
      scheduleId: selectedSchedule?._id,
    });
  };

  return (
    <Row>
      <Col md="6">
        <h6 className="mt-3">Assigned schedules</h6>
        {employeeData?.assignedSchedules?.length == 0 && (
          <div
            className="p-3 mt-2"
            style={{ border: "1px solid lightGrey", borderRadius: 8 }}
          >
            <p>No assigned schedules</p>
          </div>
        )}
        {employeeData?.assignedSchedules?.map((schedule) => (
          <div
            className="p-3 mt-2"
            style={{ border: "1px solid lightGrey", borderRadius: 8 }}
          >
            {schedule?.schedule?._id === employeeData?.activeSchedule?._id ? (
              <Badge color="primary">Active</Badge>
            ) : (
              <Badge color="red">Inactive</Badge>
            )}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="fw-bold">Working days</p>
              <p>
                {schedule?.schedule?.workingDays?.map(
                  (el) => daysOfWeek[el] + " "
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Working time</p>
              <p>
                From {schedule?.schedule?.beginTime} to{" "}
                {schedule?.schedule?.endTime}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Assignement date</p>
              <p>{moment(schedule.assignedDate).format("DD MMMM YYYY")}</p>
            </div>
          </div>
        ))}
        <div className="mt-4 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              if (employeeData?.activeSchedule) {
                setSelectedSchedule(employeeData?.activeSchedule);
              }
              modalHandlers.open();
            }}
            leftSection={<PlusCircle size={16} />}
          >
            Assign a new schedule
          </Button>
        </div>
      </Col>
      <Modal
        opened={modalOpened}
        onClose={modalHandlers.close}
        title="Assign a new schedule"
        size={"lg"}
      >
        {isLoading ? (
          <div className="m-5 p-5 d-flex justify-content-center align-items-center">
            <Spinner size="sm" />
          </div>
        ) : (
          <>
            <div className="p-1">
            <LoadingOverlay visible={updateAssignedScheduleMutation.isLoading} />
              <p>Click on the schedule you want to assign to this employee</p>
              {schedules?.map((schedule) => (
                <div
                  onClick={() => {
                    setSelectedSchedule(schedule);
                  }}
                  className="p-3 mt-2"
                  style={{
                    border:
                      selectedSchedule?._id === schedule?._id
                        ? `2px solid ${COLORS.primary}`
                        : "1px solid lightGrey",
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-bold">Working days</p>
                    <p>
                      {schedule?.workingDays?.map((el) => daysOfWeek[el] + " ")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-bold">Working time</p>
                    <p>
                      From {schedule?.beginTime} to {schedule?.endTime}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fw-bold">Creation date</p>
                    <p>{moment(schedule?.createdAt).format("DD MMMM YYYY")}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-3 d-flex justify-content-center align-items-center">
              <Button onClick={handleSave}>Save</Button>
            </div>
          </>
        )}
      </Modal>
    </Row>
  );
};

export default SchedulesTab;
