/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Breadcrumbs, H4, H5, H6 } from "../AbstractElements";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  Button,
  Drawer,
  InputLabel,
  NumberInput,
  PasswordInput,
  Select,
  TextInput,
} from "@mantine/core";
import { CheckCircle, PlusCircle } from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../recoil/atoms";
import moment from "moment";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "../static";

const clients = [
  {
    id: 1,
    name: "John Doe",
    phone: "555-1234",
    email: "john.doe@example.com",
    clientSince: "2022-01-01",
  },
  {
    id: 2,
    name: "Jane Doe",
    phone: "555-5678",
    email: "jane.doe@example.com",
    clientSince: "2021-05-15",
  },
  {
    id: 3,
    name: "Bob Smith",
    phone: "555-9012",
    email: "bob.smith@example.com",
    clientSince: "2022-03-27",
  },
  // Generate 27 random clients
  ...Array(27)
    .fill()
    .map((_, i) => ({
      id: i + 4, // Start IDs from 4 (assuming previous clients have IDs 1-3)
      name: generateRandomName(),
      phone: generateRandomPhone(),
      email: generateRandomEmail(),
      clientSince: generateRandomClientSinceDate(),
    })),
];

// Functions to generate random data
function generateRandomName() {
  const firstNames = [
    "Alice",
    "Bob",
    "Charlie",
    "David",
    "Emily",
    "Frank",
    "Grace",
    "Henry",
    "Isabel",
    "Jack",
  ];
  const lastNames = [
    "Johnson",
    "Williams",
    "Brown",
    "Jones",
    "Miller",
    "Davis",
    "Garcia",
    "Rodriguez",
    "Wilson",
    "Moore",
  ];
  return `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${
    lastNames[Math.floor(Math.random() * lastNames.length)]
  }`;
}

function generateRandomPhone() {
  const areaCodes = ["201", "310", "415", "502", "617", "704", "808", "907"];
  const prefix = Math.floor(Math.random() * 900) + 100;
  const lineNumber = Math.floor(Math.random() * 9000) + 1000;
  return `${
    areaCodes[Math.floor(Math.random() * areaCodes.length)]
  }-${prefix}-${lineNumber}`;
}

function generateRandomEmail() {
  const names = generateRandomName().split(" ");
  const domain = ["gmail.com", "hotmail.com", "yahoo.com"][
    Math.floor(Math.random() * 3)
  ];
  return `${names[0].toLowerCase()}.${names[1].toLowerCase()}@${domain}`;
}

function generateRandomClientSinceDate() {
  const minYear = 2020;
  const maxYear = 2024;
  const year = Math.floor(Math.random() * (maxYear - minYear + 1)) + minYear;
  const month = Math.floor(Math.random() * 12) + 1; // 1-12 for months
  const day = Math.floor(Math.random() * 28) + 1; // 1-28 for days (adjust for leap years if needed)
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}

const HourButtons = ({ startHour, endHour }) => {
  const buttons = [];

  for (let hour = startHour; hour < endHour; hour++) {
    const buttonText = `${hour}h-${hour + 1}h`;
    buttons.push(
      <Button variant="outline" className="mt-2" key={buttonText} value={hour}>
        {buttonText}
      </Button>
    );
  }

  return (
    <div className="d-flex justify-content-between w-75 flex-wrap">
      {buttons}
    </div>
  );
};

const CreateReservation = () => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const [newClient, setNewClient] = useState();
  const [reservationData, setReservationData] = useState();
  const [activeWorkDays, setActiveWorkDays] = useState([]);
  const [creaneaux, setCreneaux] = useState();

  const org = useRecoilValue(adminAtom);
  const handleChangeNewClient = ({ target }) => {
    setNewClient({ ...newClient, [target.name]: target.value });
  };

  useEffect(() => {
    const activeDays = org?.workHours?.map((el) => {
      if (el.isClosed !== true) {
        return el.dayOfWeek;
      }
    });
    setActiveWorkDays(activeDays);
  }, [org]);

  const getCreneaux = () => {
    return useQuery(
      ["getOrg", reservationData],
      async () => {
        if (reservationData.date && reservationData.serviceId) {
          return await axios.get(
            `${API}/api/s/reservation/disponibility`,
            {
              params: {
                date: reservationData?.date,
                serviceId: reservationData?.serviceId,
              },
            },
            { withCredentials: true }
          );
        }
      },
      {
        onSuccess: (orgResponse) => {
          setCreneaux(orgResponse?.data);
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
      }
    );
  };

  getCreneaux();

  return (
    <>
      <Breadcrumbs parent={t("Bookings")} title={t("New")} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{t("CreateReservation")}</H5>
                <span>{t("CreateReservationSubtitle")}</span>
              </CardHeader>
              <CardBody>
                <Row className="d-flex align-items-end">
                  <Select
                    className="w-75"
                    label="Client"
                    placeholder="Tapez le nom d'un client pour effectuer une recherche"
                    searchable
                    disabled={newClient}
                    data={clients.map((client) => {
                      return {
                        label: client.name,
                        value: client.id.toString(),
                      };
                    })}
                  />
                  <Button
                    variant="outline"
                    onClick={open}
                    leftSection={<PlusCircle size={15} />}
                  >
                    Nouveau client
                  </Button>
                </Row>
                <Row className="mt-3">
                  <Select
                    className="w-75"
                    label="Service"
                    placeholder="Choisissez le service que le client souhaite réserver"
                    searchable
                    data={org?.services?.map((el) => {
                      return { label: el.name, value: el.id };
                    })}
                    onChange={val => setReservationData({...reservationData, serviceId: val})}
                  />
                </Row>
                <Row className="mt-3">
                  <DatePickerInput
                    className="w-75"
                    label="Date de la réservation"
                    placeholder="Choisir une date"
                    locale="fr"
                    minDate={new Date()}
                    clearable
                    error={
                      reservationData?.date &&
                      !activeWorkDays?.includes(
                        moment(reservationData?.date).day()
                      ) &&
                      "Vous avez choisi un jour hors de vos jours de travail"
                    }
                    value={reservationData?.date}
                    onChange={(date) =>
                      setReservationData({ ...reservationData, date })
                    }
                  />
                </Row>
                <Row className="mt-3">
                  <InputLabel>{t("TimeSlot")}</InputLabel>
                  <HourButtons startHour={9} endHour={17} />
                </Row>
                <Row className="d-flex content-end mt-3 w-100">
                  <Button
                    variant="filled"
                    size="lg"
                    leftSection={<CheckCircle size={15} />}
                  >
                    {t("ValidateBooking")}
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Drawer
          position="right"
          opened={opened}
          onClose={close}
          title="Nouveau client"
        >
          <TextInput
            label="Nom"
            placeholder="Nom"
            name="lastName"
            value={newClient?.lastName}
            onChange={handleChangeNewClient}
          />
          <TextInput
            label="Prénom"
            placeholder="Prénom"
            name="firstName"
            value={newClient?.firstName}
            onChange={handleChangeNewClient}
          />
          <NumberInput
            label={t("Phone")}
            placeholder={t("Phone")}
            type="tel"
            onChange={(val) => setNewClient({ ...newClient, phoneNumber: val })}
          />
          <TextInput
            label="Email"
            placeholder="Email"
            type="email"
            name="email"
            value={newClient?.email}
            onChange={handleChangeNewClient}
          />
          <Button className="mt-4" variant="filled" onClick={close}>
            {t("Save")}
          </Button>
        </Drawer>
      </Container>
    </>
  );
};

export default CreateReservation;
