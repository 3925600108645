/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import DashboardCalendar from "../Components/Calendar/DashboardCalendar";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "../static";
import { Skeleton } from "@mantine/core";
import { useRecoilState } from "recoil";
import { adminAtom } from "../recoil/atoms";

const Dashboard = () => {
  const { t } = useTranslation();
  const [loading, setLoading]= useState(false);

  
  const [org, setOrg] = useRecoilState(adminAtom);
  const [user, setUser] = useRecoilState(adminAtom);

  const getOrg = () => {
    return useQuery(
      "getOrg",
      async () => {
        return await axios.get(`${API}/api/s/org`, { withCredentials: true });
      },
      {
        onSuccess: (orgResponse) => {
          setOrg(orgResponse?.data);
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
      }
    );
  };

  const getUser = () => {
    return useQuery(
      "getUser",
      async () => {
        return await axios.get(`${API}/api/user`, { withCredentials: true });
      },
      {
        onSuccess: (userResponse) => {
          setUser(userResponse?.data);
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
      }
    );
  };

  getOrg();
  getUser();

  return (
    <>
      {/* <Breadcrumbs mainTitle="Sample Page" /> */}
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{t("Dashboard")}</H5>
                <span>{t("DashboardSubtitle")}</span>
              </CardHeader>
              <CardBody>
                <Skeleton
                  visible={loading}
                  height={500}
                  width={"100%"}
                  radius={8}
                >
                  <DashboardCalendar />
                </Skeleton>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
