/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { H4, H5 } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import {
  Button,
  Fieldset,
  InputLabel,
  LoadingOverlay,
  NumberInput,
  SegmentedControl,
  Switch,
  TextInput,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../../../static";
import axios from "axios";
import { toast } from "react-toastify";
import { PlusCircle } from "react-feather";

const LeaveTypesTab = () => {
  const [loading, setLoading] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState();
  const [leaveTypeData, setLeaveTypeData] = useState();

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const getMyLeaves = () => {
    return useQuery(
      "getLeaveTypes",
      async () => {
        return await axios.get(`${API}/admin-api/V1/leave-type/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setLeaveTypes(data.data);
        },
      }
    );
  };

  const { isLoading } = getMyLeaves();

  const handleChange = (e) => {
    setLeaveTypeData({ ...leaveTypeData, [e.target.name]: e.target.value });
  };

  const createLeaveTypeMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/leave-type/create`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getLeaveTypes");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const updateLeaveTypeMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/leave-type/update/${leaveTypeData?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getLeaveTypes");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (leaveTypeData?.new) {
      createLeaveTypeMutation.mutate({ ...leaveTypeData });
    } else {
      updateLeaveTypeMutation.mutate({ ...leaveTypeData });
    }
  };

  return (
    <>
      {isLoading ? (
        <Container className="mt-3 d-flex justify-content-center align-items-center">
          <Spinner className="mt-5" />
        </Container>
      ) : (
        <Container className="mt-3">
          <H4>Add or edit your leave types</H4>
          <Row>
            <Col md={5} className="mt-2">
              {leaveTypes?.map((lt, key) => (
                <Button
                  key={key}
                  color="primary"
                  variant={
                    lt?._id === leaveTypeData?._id ? "filled" : "outline"
                  }
                  className="w-100 mt-1"
                  onClick={() => setLeaveTypeData(lt)}
                >
                  {lt?.englishName}
                </Button>
              ))}
              <Button
                color="primary"
                variant={leaveTypeData?.new ? "filled" : "outline"}
                leftSection=<PlusCircle size={14} />
                className="w-100 mt-1"
                onClick={() =>
                  setLeaveTypeData({
                    new: true,
                    englishName: "",
                    arabicName: "",
                    defaultDays: 0,
                    type: "Default",
                    active: true,
                  })
                }
              >
                {t("NewLeaveType")}
              </Button>
            </Col>
            <Col md={7}>
              {leaveTypeData && (
                <Fieldset legend={t("NewLocationInfo")} className="mt-3">
                  <LoadingOverlay visible={loading} />
                  {/* <NumberInput className='mt-2' label={t('Longitude')} value={newPosition?.lng} />
                        <NumberInput className='mt-2' label={t('Latitude')} value={newPosition?.lat} /> */}
                  <TextInput
                    className="mt-2"
                    label="English name"
                    placeholder="English name of this leave type"
                    value={leaveTypeData?.englishName}
                    name="englishName"
                    onChange={handleChange}
                  />
                  <TextInput
                    className="mt-2"
                    label="Arabic name"
                    placeholder="Arabic name of this leave type"
                    value={leaveTypeData?.arabicName}
                    name="arabicName"
                    onChange={handleChange}
                  />
                  <NumberInput
                    className="mt-2"
                    label="Default days"
                    placeholder="Default days of this leave type"
                    value={leaveTypeData?.defaultDays}
                    name="defaultDays"
                    onChange={(val) =>
                      setLeaveTypeData({ ...leaveTypeData, defaultDays: val })
                    }
                  />
                  <div className="mt-2">
                    <InputLabel>Type</InputLabel>
                    <SegmentedControl
                      className="mx-4"
                      value={leaveTypeData?.type}
                      onChange={(val) =>
                        setLeaveTypeData({ ...leaveTypeData, type: val })
                      }
                      data={["Default", "Sick", "Maternity"]}
                    />
                  </div>
                  <div className="mt-2">
                    <Switch
                      checked={leaveTypeData?.active}
                      onChange={(event) =>
                        setLeaveTypeData({
                          ...leaveTypeData,
                          active: event.currentTarget.checked,
                        })
                      }
                      label="This leave type is active"
                    />
                  </div>
                  <div
                    className="w-100 mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button size="sm" onClick={handleSave}>
                      {t("SaveChanges")}
                    </Button>
                  </div>
                </Fieldset>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default LeaveTypesTab;
