import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dateClick
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr';

const DashboardCalendar = () => {
    const [events, setEvents] = useState([]);

    const handleDateClick = (arg) => {
        const title = prompt('Enter event title:');
        if (title) {
            setEvents([...events, { title, date: arg.date }]);
        }
    };

    const handleEventClick = (info) => {
        alert(`Event: ${info.event.title}\nStart: ${info.event.startStr}\nEnd: ${info.event.endStr}`);
      };

    return (
        <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin, listPlugin]}
            initialView="timeGridWeek" // set week view by default
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'timeGridWeek, timeGridDay, listWeek' // user can switch between the two
            }}
            events={events}
            dateClick={handleDateClick}
            locale={frLocale}
            eventClick={handleEventClick}
            allDaySlot={false}
        />
    );
};

export default DashboardCalendar;