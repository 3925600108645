import { Button, LoadingOverlay, PasswordInput } from "@mantine/core";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { API } from "../../static";
import { useRecoilValue } from "recoil";
import { employeeDataAtom } from "../../recoil/atoms";
import { useTranslation } from "react-i18next";

const PasswordTab = () => {
  const [password, setPassword] = useState();
  const employeeData = useRecoilValue(employeeDataAtom);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const updateEmployeePasswordMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/employee/update-password/${employeeData?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployees");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSaveNewPassword = async (e) => {
    e.preventDefault();
    updateEmployeePasswordMutation.mutate({ password: password });
  };

  return (
    <Row>
      <Col md="6">
        <LoadingOverlay visible={updateEmployeePasswordMutation.isLoading} />
        <>
          <PasswordInput
            className="mt-2"
            label="New Password"
            placeholder="New Password"
            onChange={({ target }) => setPassword(target.value)}
            name="password"
          />
          <div
            className="w-100 mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button className="mt-2" size="sm" onClick={handleSaveNewPassword}>
              {t("SaveChanges")}
            </Button>
          </div>
        </>
      </Col>
    </Row>
  );
};

export default PasswordTab;
