import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Btn, LI } from "../../../AbstractElements";
import Avatar from 'react-avatar';
import LogoutClass from "./Logout";
import { Link } from "react-router-dom";
import { User } from "react-feather";
import { Card } from "reactstrap";
import { COLORS } from "../../../static";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../recoil/atoms";

const ProfileClass = () => {
    const [profileDropdown, setProfileDropdown] = useState(false);
    const admin = useRecoilValue(adminAtom);

    const {t} = useTranslation();

    console.log(admin)

    return (
        <Fragment>
            <LI attrLI={{ className: "onhover-dropdown" }}>
                <div className={`translate_wrapper ${profileDropdown ? "active" : ""}`}>
                    <div className="current_lang">
                        <Avatar name={`${admin?.admin?.firstName} ${admin?.admin?.lastName}`} color={COLORS.primary} round size={32} />
                    </div>
                    <div className={`more_lang onhover-show-div ${profileDropdown ? "active" : ""}`}>
                        <div className="lang">
                            <LogoutClass />
                        </div>
                    </div>
                </div>
            </LI>
        </Fragment>
    );
};

export default ProfileClass;
