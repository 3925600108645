/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { H6 } from "../../AbstractElements";
import { useRecoilValue } from "recoil";
import { employeeDataAtom } from "../../recoil/atoms";
import moment from "moment";
import {
  Badge,
  Button,
  LoadingOverlay,
  Modal,
  NumberInput,
} from "@mantine/core";
import { Edit, PlusCircle, Trash2 } from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API, COLORS } from "../../static";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const LeaveTypesTab = () => {
  const employeeData = useRecoilValue(employeeDataAtom);
  const [newModalOpened, newModalHandlers] = useDisclosure(false);
  const [editModalOpened, editModalHandlers] = useDisclosure(false);
  const [deleteModalOpened, deleteModalHandlers] = useDisclosure(false);

  const [leaveTypes, setLeaveTypes] = useState();
  const [selectedLeaveType, setSelectedLeaveType] = useState();

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const getLeaveTypes = () => {
    return useQuery(
      "getLeaveTypes",
      async () => {
        return await axios.get(`${API}/admin-api/V1/leave-type/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setLeaveTypes(data.data);
        },
      }
    );
  };

  const { isLoading } = getLeaveTypes();

  const addNewLeaveType = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/employee/add-leave-type`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployee");
      toast.success(t("SavedSuccessfully"));
      newModalHandlers.close();
      setSelectedLeaveType(null);
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSave = () => {
    addNewLeaveType.mutate({
      employeeId: employeeData?._id,
      leaveTypeId: selectedLeaveType?._id,
    });
  };

  const editLeaveType = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/employee/edit-credit-days`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployee");
      toast.success(t("SavedSuccessfully"));
      editModalHandlers.close();
      setSelectedLeaveType(null);
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSaveEdit = () => {
    editLeaveType.mutate({
      employeeId: employeeData?._id,
      leaveTypeId: selectedLeaveType?.leaveType?._id,
      creditDays: selectedLeaveType?.creditDays,
    });
  };

  const deleteLeaveType = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/employee/delete-assigned-leave-type`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployee");
      toast.success(t("SavedSuccessfully"));
      deleteModalHandlers.close();
      setSelectedLeaveType(null);
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleDelete = () => {
    deleteLeaveType.mutate({
      employeeId: employeeData?._id,
      leaveTypeId: selectedLeaveType?.leaveType?._id,
    });
  };

  return (
    <Row>
      <Col md="6">
        <h6 className="mt-3">Assigned Leave types</h6>
        {employeeData?.assignedLeaveTypes?.length == 0 && (
          <div
            className="p-3 mt-2"
            style={{ border: "1px solid lightGrey", borderRadius: 8 }}
          >
            <p>No assigned leave-types</p>
          </div>
        )}
        {employeeData?.assignedLeaveTypes?.map((lt) => (
          <div
            className="p-3 mt-2"
            style={{ border: "1px solid lightGrey", borderRadius: 8 }}
          >
            <div className="d-flex justify-content-end align-items-center mb-3">
              <Button
                onClick={() => {
                  setSelectedLeaveType(lt);
                  editModalHandlers.open();
                }}
                leftSection={<Edit size={16} />}
                className="mx-1"
                variant="outline"
              >
                Edit credit days
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setSelectedLeaveType(lt);
                  deleteModalHandlers.open();
                }}
              >
                <Trash2 size={16} />
              </Button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">English name</p>
              <p>{lt?.leaveType?.englishName}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Arabic name</p>
              <p>{lt?.leaveType?.arabicName}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Remaining days</p>
              <p>{lt?.creditDays} days</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="fw-bold">Type</p>
              <p>{lt?.leaveType?.type}</p>
            </div>
          </div>
        ))}
        <div className="mt-4 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              newModalHandlers.open();
            }}
            leftSection={<PlusCircle size={16} />}
          >
            Assign a new leave type
          </Button>
        </div>
      </Col>
      <Modal
        opened={newModalOpened}
        onClose={() => {
          newModalHandlers.close();
          setSelectedLeaveType(null);
        }}
        title="Assign a new schedule"
        size={"lg"}
      >
        {isLoading ? (
          <div className="m-5 p-5 d-flex justify-content-center align-items-center">
            <Spinner size="sm" />
          </div>
        ) : (
          <>
            <div className="p-1">
              <LoadingOverlay visible={addNewLeaveType.isLoading} />
              {leaveTypes?.filter?.(
                (lt) =>
                  !employeeData?.assignedLeaveTypes
                    ?.map((el) => el.leaveType._id)
                    .includes(lt._id)
              ).length !== 0 ? (
                <>
                  <p>
                    Click on the leave type you want to add to this employee
                  </p>
                  {leaveTypes
                    ?.filter?.(
                      (lt) =>
                        !employeeData?.assignedLeaveTypes
                          ?.map((el) => el.leaveType._id)
                          .includes(lt._id)
                    )
                    .map((leaveType) => (
                      <div
                        onClick={() => {
                          setSelectedLeaveType(leaveType);
                        }}
                        className="p-3 mt-2"
                        style={{
                          border:
                            selectedLeaveType?._id === leaveType?._id
                              ? `2px solid ${COLORS.primary}`
                              : "1px solid lightGrey",
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-bold">English name</p>
                          <p>{leaveType?.englishName}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-bold">Arabic name</p>
                          <p>{leaveType?.arabicName}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-bold">Default days</p>
                          <p>{leaveType?.defaultDays}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-bold">Creation date</p>
                          <p>
                            {moment(leaveType?.createdAt).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <p>
                  All leave types have already been assigned to this employee
                </p>
              )}
            </div>
            <div className="mt-3 d-flex justify-content-center align-items-center">
              <Button disabled={!selectedLeaveType} onClick={handleSave}>
                Save
              </Button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        opened={editModalOpened}
        onClose={() => {
          editModalHandlers.close();
          setSelectedLeaveType(null);
        }}
        title="Edit credit days for this leave type"
      >
        <LoadingOverlay visible={editLeaveType.isLoading} />
        <NumberInput
          className="mt-2"
          label="Remaining days of this leave type for this employee"
          placeholder="Remaining days of this leave type for this employee"
          value={selectedLeaveType?.creditDays}
          name="defaultDays"
          suffix=" Remaining days"
          onChange={(val) =>
            setSelectedLeaveType({ ...selectedLeaveType, creditDays: val })
          }
        />
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button onClick={handleSaveEdit}>Save changes</Button>
        </div>
      </Modal>
      <Modal
        opened={deleteModalOpened}
        onClose={() => {
          deleteModalHandlers.close();
          setSelectedLeaveType(null);
        }}
        title="Delete this leave type for this employee"
      >
        <LoadingOverlay visible={deleteLeaveType.isLoading} />
        <p>
          Are you sure you want to delete this leave type for this employee ?
        </p>
        <p>
          Note : Deleting this leave type will also delete credit days data{" "}
        </p>
        <div className="mt-3 d-flex justify-content-around align-items-center">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="red"
            leftSection={<Trash2 size={15} />}
          >
            Confirm delete
          </Button>
        </div>
      </Modal>
    </Row>
  );
};

export default LeaveTypesTab;
