import React from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import EmployeesTable from "../Components/Employees/Datatable";

const EmployeesList = () => {

  return (
    <>
      <Breadcrumbs
        mainTitle={"Employees list"}
        parent={"Employees"}
        title={"List"}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <EmployeesTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeesList;
