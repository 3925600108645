/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Badge, Button, Input, TextInput } from "@mantine/core";
import { ChevronRight, PlusCircle, Search } from "react-feather";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "../../static";
import { useNavigate } from "react-router-dom";

const EmployeesTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [employees, setEmployees] = useState();

  const navigate = useNavigate();

  const getEmployees = () => {
    return useQuery(
      "getSchedules",
      async () => {
        return await axios.post(`${API}/admin-api/V1/employee/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setEmployees(data.data);
        },
      }
    );
  };

  const { isLoading } = getEmployees();

  const { t } = useTranslation();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Search should be case-insensitive
  };

  const filterData = (data) => {
    return data?.filter((row) => {
      const searchTerm = searchQuery.toLowerCase();
      const firstName = row.firstName.toLowerCase();
      const lastName = row.lastName.toLowerCase();
      const phone = row.phoneNumber.toLowerCase();
      const email = row.email.toLowerCase();
      const role = row.role.toLowerCase();
      return (
        firstName.includes(searchTerm) ||
        lastName.includes(searchTerm) ||
        phone.includes(searchTerm) ||
        role.includes(searchTerm) ||
        email.includes(searchTerm)
      );
    });
  };

  const filteredData = filterData(employees);

  const columns = [
    {
      name: "#",
      selector: (row, key) => key + 1,
      width: "80px",
    },
    {
      name: t("FullName"),
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: t("Role"),
      selector: (row) => row.role,
    },
    {
      name: "Status",
      selector: (row) =>
        row.active ? (
          <Badge color="green">Active</Badge>
        ) : (
          <Badge color="red">Inactive</Badge>
        ),
    },
    {
      name: t("Email"),
      selector: (row) => row.email,
    },
    {
      name: t("SignupDate"),
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <Button
          onClick={() => navigate(`/employee/details/${row._id}`)}
          variant="outline"
          rightSection={<ChevronRight size={15} />}
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Input
          leftSection={<Search size={16} />}
          placeholder={t("SearchPlaceholder")}
          className="mb-3 w-50"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Button
          onClick={() => navigate("/employee/new")}
          leftSection={<PlusCircle size={16} />}
        >
          Create new employee
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        direction="auto"
        fixedHeaderScrollHeight="300px"
        highlightOnHover
        pagination
        responsive
        striped
        subHeaderAlign="right"
        subHeaderWrap
      />
    </>
  );
};

export default EmployeesTable;
