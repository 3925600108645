export const debounce = (func, delay) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export function formatTimeObject(timeObj) {
  if(!timeObj.minutes) {
    timeObj.minutes = 0;
  }
  if(!timeObj.hour) {
    timeObj.hour = 0;
  }
  const hour = timeObj.hour.toString().padStart(2, '0');
  const minutes = timeObj.minutes.toString().padStart(2, '0');
  return `${hour}:${minutes}`;
}

export function parseTimeString(timeString) {
  const [hour, minutes] = timeString.split(':');
  return {
    hour: parseInt(hour, 10),
    minutes: parseInt(minutes, 10),
  };
}

