import React, { useRef } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import DashboardCalendar from "../Components/Calendar/DashboardCalendar";
import { useTranslation } from "react-i18next";
import { Button, CopyButton, Divider, Group, TextInput } from "@mantine/core";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';

const Share = () => {
  const { t } = useTranslation();
  const qrCodeRef = useRef(null);

  const downloadQRCode = () => {
    html2canvas(qrCodeRef.current).then((canvas) => {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'qr-code.png';
      link.click();
    });
  };

  return (
    <>
      {/* <Breadcrumbs mainTitle="Sample Page" /> */}
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{t('Share')}</H5>
                <span>{t('ShareSubtitle')}</span>
              </CardHeader>
              <CardBody>
                <Group>
                  <div className="d-flex flex-column items-center">
                    <div ref={qrCodeRef}>
                      <QRCode value="https://www.vanueease.com/kbh" size={128} />
                    </div>
                    <Button variant="outline" onClick={downloadQRCode}>{t('Download')}</Button>
                  </div> 
                  <Divider orientation="vertical" />
                  <TextInput
                    disabled
                    value={"https://www.vanueease.com/kbh"}
                    label={t('BookingLink')}
                    className="w-75"
                    rightSectionWidth={"fit-content"}
                    rightSection={<CopyButton value="https://www.vanueease.com/kbh">
                      {({ copied, copy }) => (
                        <Button color={copied ? 'teal' : 'primary'} onClick={copy}>
                          {copied ? t('Copied') : t('CopyURL')}
                        </Button>
                      )}
                    </CopyButton>} />
                </Group>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Share;
