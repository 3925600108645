import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../static";
import { useResetRecoilState } from "recoil";
import { adminAtom } from "../../../recoil/atoms";

const LogoutClass = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const resetAdmin = useResetRecoilState(adminAtom);

  const Logout = () => {
    resetAdmin();
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown p-0 w-100", onClick: Logout }}>
        <Btn
          attrBtn={{
            as: Card.Header,
            className: "btn w-100",
            color: "default",
            style: { textAlign: "left" },
          }}
        >
          <Link to={`${process.env.PUBLIC_URL}/login`}>
            <LogOut className="mx-1" color={COLORS.primary} />
            {t("Logout")}
          </Link>
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
