/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
  Button,
  InputLabel,
  LoadingOverlay,
  Modal,
  PasswordInput,
  SegmentedControl,
  Switch,
  Tabs,
  TextInput,
} from "@mantine/core";
import { useRecoilState } from "recoil";
import { employeeDataAtom } from "../../recoil/atoms";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API, COLORS } from "../../static";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Avatar from "react-avatar";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import moment from "moment";
import { MapPin } from "react-feather";
import { useDisclosure } from "@mantine/hooks";

const WorkTab = () => {
  const [employeeData, setEmployeeData] = useRecoilState(employeeDataAtom);
  const [modalOpened, modalHandlers] = useDisclosure(false);

  const [locations, setLocations] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleSave = async (e) => {
    e.preventDefault();
    updateEmployeeMutation.mutate({ ...employeeData });
  };

  const handleChange = ({ target }) => {
    setEmployeeData({ ...employeeData, [target.name]: target.value });
  };

  const updateEmployeeMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/employee/update/${employeeData?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployees");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const getLocations = () => {
    return useQuery(
      "getLocations",
      async () => {
        return await axios.get(`${API}/admin-api/V1/location/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setLocations(data.data);
        },
      }
    );
  };

  const { isLoading } = getLocations();

  const updateAssignedLocationMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/employee/edit-active-location`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployee");
      toast.success(t("SavedSuccessfully"));
      modalHandlers.close();
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSaveNewLocation = () => {
    updateAssignedLocationMutation.mutate({
      employeeId: employeeData?._id,
      locationId: selectedLocation?._id,
    });
  };

  return (
    <Row>
      <Col md="6">
        <LoadingOverlay visible={updateEmployeeMutation.isLoading} />
        <TextInput
          className="mt-2"
          label="Employee ID"
          placeholder="Employee ID"
          value={employeeData?.employeeId}
          onChange={handleChange}
          name="employeeId"
        />
        <TextInput
          className="mt-2"
          label="Role"
          placeholder="Role"
          value={employeeData?.role}
          onChange={handleChange}
          name="role"
        />
        <TextInput
          className="mt-2"
          label="Department"
          placeholder="Department"
          value={employeeData?.department}
          onChange={handleChange}
          name="department"
        />
        <div className="mt-2">
          <InputLabel>Employment type</InputLabel>
          <SegmentedControl
            className="mx-4"
            value={employeeData?.type}
            onChange={(val) =>
              setEmployeeData({ ...employeeData, employmentType: val })
            }
            data={["Full time", "Part time", "Internship"]}
          />
        </div>
        <DatePickerInput
          label="Employment date"
          value={moment(employeeData?.dateOfEmployment)}
          onChange={(val) =>
            setEmployeeData({ ...employeeData, dateOfEmployment: val })
          }
        />
        <div
          className="w-100 mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button size="sm" onClick={handleSave}>
            {t("SaveChanges")}
          </Button>
        </div>
      </Col>
      <Col md={6}>
        <div className="mt-2">
          <InputLabel>Assigned Location</InputLabel>
          <div
            className="p-3 mt-2"
            style={{ border: "1px solid lightGrey", borderRadius: 8 }}
          >
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="fw-bold">Name</p>
              <p>{employeeData?.activeLocation?.name}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="fw-bold">Address</p>
              <p>{employeeData?.activeLocation?.address}</p>
            </div>
          </div>
          <div
            className="w-100 mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              leftSection={<MapPin size={15} />}
              size="sm"
              onClick={() => {
                if (employeeData?.activeLocation) {
                  setSelectedLocation(employeeData?.activeLocation);
                }
                modalHandlers.open();
              }}
            >
              Assign to another office
            </Button>
          </div>
        </div>
      </Col>
      <Modal
        opened={modalOpened}
        onClose={modalHandlers.close}
        title="Assign to another office"
        size={"lg"}
      >
        <>
          <div className="p-1">
            <LoadingOverlay
              visible={updateAssignedLocationMutation.isLoading}
            />
            <p>Click on the location you want to assign to this employee</p>
            {locations?.map((location) => (
              <div
                onClick={() => {
                  setSelectedLocation(location);
                }}
                className="p-3 mt-2"
                style={{
                  border:
                    selectedLocation?._id === location?._id
                      ? `2px solid ${COLORS.primary}`
                      : "1px solid lightGrey",
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">Name</p>
                  <p>{location?.name}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">Full Address</p>
                  <p>{location?.address}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">Coordinates</p>
                  <p>
                    {location?.longitude} , {location?.latitude}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">Creation date</p>
                  <p>{moment(location?.createdAt).format("DD MMMM YYYY")}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button onClick={handleSaveNewLocation}>Save changes</Button>
          </div>
        </>
      </Modal>
    </Row>
  );
};

export default WorkTab;
