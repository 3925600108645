/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import {
  Button,
  InputLabel,
  LoadingOverlay,
  NumberInput,
  Switch,
} from "@mantine/core";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../recoil/atoms";
import { parseTimeString } from "../../../utils/functions";
import { PlusCircle, Trash2 } from "react-feather";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API } from "../../../static";
import axios from "axios";
import { toast } from "react-toastify";

const WorkingHoursTab = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState();

  const getSchedules = () => {
    return useQuery(
      "getSchedules",
      async () => {
        return await axios.get(`${API}/admin-api/V1/schedule/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          let dataToSave = data.data.map((el) => {
            let sortedWorkdays = el.workingDays.sort();
            let endObject = parseTimeString(el.endTime);
            let beginObject = parseTimeString(el.beginTime);
            return {
              ...el,
              workingDays: sortedWorkdays,
              end: endObject,
              begin: beginObject,
            };
          });
          setSchedules(dataToSave);
        },
      }
    );
  };

  const { isLoading } = getSchedules();

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const createSchedule = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/schedule/create`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getSchedules");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const editSchedule = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/schedule/update/${selectedSchedule?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getSchedules");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleSave = async (e) => {
    setLoading(true);
    if (selectedSchedule?.new) {
      createSchedule.mutate({
        ...selectedSchedule,
        beginTime: `${selectedSchedule?.begin?.hour}:${
          selectedSchedule?.begin?.minutes === 0
            ? "00"
            : selectedSchedule?.begin?.minutes
        }`,
        endTime: `${selectedSchedule?.end?.hour}:${
          selectedSchedule?.end?.minutes === 0
            ? "00"
            : selectedSchedule?.end?.minutes
        }`,
      });
    } else {
      editSchedule.mutate({
        ...selectedSchedule,
        beginTime: `${selectedSchedule?.begin?.hour}:${
          selectedSchedule?.begin?.minutes === 0
            ? "00"
            : selectedSchedule?.begin?.minutes
        }`,
        endTime: `${selectedSchedule?.end?.hour}:${
          selectedSchedule?.end?.minutes === 0
            ? "00"
            : selectedSchedule?.end?.minutes
        }`,
      });
    }
  };

  return (
    <Container className="mt-3">
      <H4>Add or edit your schedules</H4>
      <LoadingOverlay visible={loading} />
      <Row>
        <Col md={5}>
          {schedules?.map((schedule, key) => (
            <Button
              key={key}
              color="primary"
              variant={
                schedule?._id === selectedSchedule?._id ? "filled" : "outline"
              }
              className="w-100 mt-1"
              onClick={() => setSelectedSchedule(schedule)}
            >
              {daysOfWeek[schedule?.workingDays[0]]} -{" "}
              {
                daysOfWeek[
                  schedule?.workingDays[schedule?.workingDays.length - 1]
                ]
              }{" "}
              | {schedule?.beginTime} - {schedule?.endTime}
            </Button>
          ))}
          <Button
            color="primary"
            variant={selectedSchedule?.new ? "filled" : "outline"}
            leftSection=<PlusCircle size={14} />
            className="w-100 mt-1"
            onClick={() =>
              setSelectedSchedule({
                new: true,
                begin: { hour: 0, minutes: 0 },
                end: { hour: 0, minutes: 0 },
                workingDays: [],
                active: true,
              })
            }
          >
            New schedule
          </Button>
        </Col>
        {selectedSchedule && (
          <Col md={7}>
            {daysOfWeek.map((day, dayKey) => (
              <Row key={dayKey} className="mt-3">
                <Col md={3}>
                  <Switch
                    label={t(day)}
                    checked={selectedSchedule?.workingDays?.includes(dayKey)}
                    onChange={(event) => {
                      if (selectedSchedule?.workingDays?.includes(dayKey)) {
                        setSelectedSchedule({
                          ...selectedSchedule,
                          workingDays: selectedSchedule?.workingDays?.filter(
                            (el) => el !== dayKey
                          ),
                        });
                      } else {
                        setSelectedSchedule({
                          ...selectedSchedule,
                          workingDays: [
                            ...selectedSchedule?.workingDays,
                            dayKey,
                          ],
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            ))}
            <div className="mt-3">
              <InputLabel>{t("From")}</InputLabel>
              <div className="d-flex justify-between mx-3">
                <NumberInput
                  min={0}
                  max={23}
                  rightSection="h"
                  rightSectionWidth={30}
                  required
                  value={selectedSchedule?.begin?.hour}
                  onChange={(val) =>
                    setSelectedSchedule({
                      ...selectedSchedule,
                      begin: { ...selectedSchedule?.begin, hour: val },
                    })
                  }
                />
                <NumberInput
                  min={0}
                  max={59}
                  rightSection=" mn"
                  rightSectionWidth={30}
                  required
                  value={selectedSchedule?.begin?.minutes}
                  onChange={(val) =>
                    setSelectedSchedule({
                      ...selectedSchedule,
                      begin: { ...selectedSchedule?.begin, minutes: val },
                    })
                  }
                />
              </div>

              <InputLabel>{t("To")}</InputLabel>

              <div className="d-flex justify-between mx-3">
                <NumberInput
                  min={0}
                  max={23}
                  rightSection="h"
                  rightSectionWidth={30}
                  required
                  value={selectedSchedule?.end?.hour}
                  onChange={(val) =>
                    setSelectedSchedule({
                      ...selectedSchedule,
                      end: { ...selectedSchedule?.end, hour: val },
                    })
                  }
                />
                <NumberInput
                  min={0}
                  max={59}
                  rightSection=" mn"
                  rightSectionWidth={30}
                  decimalScale={2}
                  required
                  value={selectedSchedule?.end?.minutes}
                  onChange={(val) =>
                    setSelectedSchedule({
                      ...selectedSchedule,
                      end: { ...selectedSchedule?.end, minutes: val },
                    })
                  }
                />
              </div>
            </div>
            <Row>
              <Col md={12} className="text-center">
                <Button className="mt-4" onClick={handleSave}>
                  {t("SaveChanges")}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default WorkingHoursTab;
