import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { adminAtom } from "../recoil/atoms";

const PrivateRoute = () => {
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const resetAdmin = useResetRecoilState(adminAtom);

  useEffect(() => {
    if(!admin?.isAuthenticated){
      resetAdmin();
    }
  }, [admin]);

  return admin?.isAuthenticated ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
};

export default PrivateRoute;
