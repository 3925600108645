import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../AbstractElements";
import { useTranslation } from "react-i18next";
import { Button, Select, Skeleton, TextInput } from "@mantine/core";
import { useRecoilState } from "recoil";
import { adminAtom } from "../recoil/atoms";
import { DateInput } from "@mantine/dates";
import moment from "moment";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useRecoilState(adminAtom);
  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const { t } = useTranslation();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* <Breadcrumbs mainTitle="Sample Page" /> */}
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{t("Profile")}</H5>
                <span>{t("ProfileSubtitle")}</span>
              </CardHeader>
              <CardBody>
                <Skeleton
                  visible={loading}
                  height={500}
                  width={"100%"}
                  radius={8}
                >
                  <Row>
                    <Col md={6} className="mt-2">
                      <TextInput
                        label={"Prénom"}
                        placeholder={"Entrez votre prénom"}
                        required
                        name="firstName"
                        onChange={handleChange}
                        value={userData?.firstName}
                      />
                    </Col>
                    <Col md={6} className="mt-2">
                      <TextInput
                        label={"Nom"}
                        placeholder={"Entrez votre nom"}
                        required
                        name="lastName"
                        onChange={handleChange}
                        value={userData?.lastName}
                      />
                    </Col>
                    <Col md={6} className="mt-2">
                      <Select
                        label={"Sexe"}
                        placeholder={"Sélectionnez votre sexe"}
                        required
                        name="gender"
                        data={[
                          { label: "Masculin", value: "1" },
                          { label: "Féminin", value: "2" },
                        ]}
                        value={userData?.gender}
                        onChange={(val) =>
                          setUserData({ ...userData, gender: val })
                        }
                      />
                    </Col>
                    <Col md={6} className="mt-2">
                    <DateInput label={"Date de naissance"} />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={12} className="text-center">
                      <Button>Sauvegarder</Button>
                    </Col>
                  </Row>
                </Skeleton>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
