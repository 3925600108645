/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/1.png";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { API } from "../static";
import { useRecoilState } from "recoil";
import { adminAtom } from "../recoil/atoms";
import { Button } from "@mantine/core";

const Login = ({ selected }) => {
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loginMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/admin/login`, data),
    onSuccess: (data) => {
      setAdmin(data.data);
      navigate(`${process.env.PUBLIC_URL}/attendances`);
    },
    onError: () => {
      toast.error(t("IncorrectCredentials"));
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    loginMutation.mutate({ username, password });
  };

  return (
    <Fragment>
      <div className="p-0 container-fluid">
        <Row>
          <Col className="col-12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4>{t("Login")}</H4>
                  <P>{t("LoginSubtitle")}</P>
                  <FormGroup>
                    <Label className="col-form-label">{t("Username")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("UsernamePlaceholder")}
                      type="email"
                      required
                      onChange={(e) => setUsername(e.target.value)}
                      defaultValue={username}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{t("Password")}</Label>
                    <Input
                      className="form-control"
                      placeholder={t("PasswordPlaceholder")}
                      type={togglePassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      defaultValue={password}
                      required
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ms-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {t("RememberPassword")}
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      {t("ForgotPassword")}
                    </a>
                    <Button
                      color="primary"
                      className="btn-block w-100 btn-lg mt-4"
                      disabled={loading ? loading : loading}
                      onClick={(e) => loginAuth(e)}
                    >
                      {t("Login")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Login;
