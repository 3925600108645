import React from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, H5 } from "../AbstractElements";
import { Tabs } from "@mantine/core";
import LeaveTypesTab from "../Components/Organization/Tabs/LeaveTypesTab";
import LocationsTab from "../Components/Organization/Tabs/LocationsTab";
import WorkingHoursTab from "../Components/Organization/Tabs/WorkingHoursTab";

const Organization = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs parent={t("Organization")} title={t("Informations")} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{t("Organization")}</H5>
                <span>{t("OrganizationSubtitle")}</span>
              </CardHeader>
              <CardBody>
                <Tabs
                  keepMounted={false}
                  defaultValue="leave-types"
                  styles={{ tabLabel: { fontSize: 20 } }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="leave-types">{t("LeaveTypes")}</Tabs.Tab>
                    <Tabs.Tab value="locations">{t("Locations")}</Tabs.Tab>
                    <Tabs.Tab value="workingHours">
                      {t("WorkingHours")}
                    </Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="leave-types">
                    <LeaveTypesTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="locations">
                    <LocationsTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="workingHours">
                    <WorkingHoursTab />
                  </Tabs.Panel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Organization;
