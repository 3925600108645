import EmployeesList from "../Pages/EmployeesList";
import CreateReservation from "../Pages/CreateReservation";
import Dashboard from "../Pages/Dashboard";
import Organization from "../Pages/Organization";
import Profile from "../Pages/Profile";
import Share from "../Pages/Share";
import Support from "../Pages/Support";
import AdministratorsList from "../Pages/AdministratorsList";
import NewAdmin from "../Pages/NewAdmin";
import NewEmployee from "../Pages/NewEmployee";
import EmployeeDetail from "../Pages/EmployeeDetail";
import AdminDetail from "../Pages/AdminDetail";
import LeavesList from "../Pages/LeavesList";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/attendances`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/leaves`, Component: <LeavesList /> },
  { path: `${process.env.PUBLIC_URL}/employees`, Component: <EmployeesList /> },
  { path: `${process.env.PUBLIC_URL}/administrators`, Component: <AdministratorsList /> },
  { path: `${process.env.PUBLIC_URL}/administrator/new`, Component: <NewAdmin /> },
  { path: `${process.env.PUBLIC_URL}/employee/new`, Component: <NewEmployee /> },
  { path: `${process.env.PUBLIC_URL}/employee/details/:id`, Component: <EmployeeDetail /> },
  { path: `${process.env.PUBLIC_URL}/administrator/details/:id`, Component: <AdminDetail /> },
  { path: `${process.env.PUBLIC_URL}/share`, Component: <Share /> },
  { path: `${process.env.PUBLIC_URL}/organization`, Component: <Organization /> },
  { path: `${process.env.PUBLIC_URL}/profile`, Component: <Profile /> },
  { path: `${process.env.PUBLIC_URL}/support`, Component: <Support /> },
];
