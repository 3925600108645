/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L, { map } from "leaflet";
import "./LocationsTab.scss";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import { Col, Container, Row, Spinner } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";
import {
  Button,
  Fieldset,
  LoadingOverlay,
  NumberInput,
  Switch,
  TextInput,
} from "@mantine/core";
import { PlusCircle } from "react-feather";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { adminAtom } from "../../../recoil/atoms";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { API } from "../../../static";

const createClusterCustomIcon = function (cluster) {
  return new divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true),
  });
};

// create custom icon
const customIcon = new Icon({
  // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconUrl: require("../../../assets/images/placeholder.png"),
  iconSize: [38, 38], // size of the icon
});

// markers
const markers = [
  {
    geocode: [48.86, 2.3522],
    popUp: "Terrain 1",
  },
  {
    geocode: [48.85, 2.3522],
    popUp: "Terrain 2",
  },
  {
    geocode: [48.855, 2.34],
    popUp: "Terrain 3",
  },
];

let markersLayer = null;

const LocationsTab = () => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [locationLoading, setLocationLoading] = useState(false);
  const [locations, setLocations] = useState();

  const ClickMarker = () => {
    const map = useMapEvents({
      click: async (e) => {
        if (markersLayer) {
          map.removeLayer(markersLayer);
        }
        console.log(e);

        const { lat, lng } = e.latlng;
        markersLayer = L.marker([lat, lng], { icon: customIcon }).addTo(map);
        try {
          setLocationLoading(true);
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`
          );
          const address = response.data.address;
          setSelectedLocation({
            ...selectedLocation,
            address: `${address.road} ${address.suburb ?? ""}, ${address.city}`,
            latitude: lat,
            longitude: lng,
          });
          setLocationLoading(false);
        } catch (error) {
          console.error(error);
          setLocationLoading(false);
        }
      },
    });

    return null;
  };

  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const getLocations = () => {
    return useQuery(
      "getLocations",
      async () => {
        return await axios.get(`${API}/admin-api/V1/location/list`);
      },
      {
        onSuccess: async (data, variables, context) => {
          setLocations(data.data);
        },
      }
    );
  };

  const { isLoading } = getLocations();

  const handleChangeLocation = (location) => {
    setSelectedLocation(location);
    mapRef.current.flyTo([location.latitude, location.longitude], 13);
    if (markersLayer) {
      mapRef.current.removeLayer(markersLayer);
    }
  };

  const createLocationMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/location/create`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getLocations");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const updateLocationMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/location/update/${selectedLocation?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getLocations");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    if (selectedLocation?.new) {
      createLocationMutation.mutate({ ...selectedLocation });
    } else {
      updateLocationMutation.mutate({ ...selectedLocation });
    }
  };

  const handleChange = (e) => {
    setSelectedLocation({ ...selectedLocation, [e.target.name]: e.target.value });
  };

  return (
    <>
      {isLoading ? (
        <Container className="mt-3 d-flex justify-content-center align-items-center">
          <Spinner className="mt-5" />
        </Container>
      ) : (
        <Container className="mt-3">
          <H4>Add or edit your locations</H4>
          <Row>
            <Col md={5} className="mt-2">
              {locations?.map((location, key) => (
                <Button
                  key={key}
                  color="primary"
                  variant={
                    location?._id === selectedLocation?._id
                      ? "filled"
                      : "outline"
                  }
                  className="w-100 mt-1"
                  onClick={() => handleChangeLocation(location)}
                >
                  {location?.name}
                </Button>
              ))}
              <Button
                color="primary"
                variant={selectedLocation?.new ? "filled" : "outline"}
                leftSection=<PlusCircle size={14} />
                className="w-100 mt-1"
                onClick={() =>
                  setSelectedLocation({
                    new: true,
                    name: "",
                    address: "",
                    longitude: 0,
                    latitude: 0,
                    active: true,
                  })
                }
              >
                {t("NewLocation")}
              </Button>
            </Col>
            <Col md={7}>
              <div
                ref={containerRef}
                style={{ width: "100%", height: "400px" }}
              >
                <MapContainer
                  center={[36.81897, 10.16579]}
                  zoom={10}
                  ref={mapRef}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <MarkerClusterGroup
                    chunkedLoading
                    iconCreateFunction={createClusterCustomIcon}
                  >
                    {/* Mapping through the markers */}
                    {selectedLocation && !selectedLocation?.new && (
                      <Marker
                        position={[
                          selectedLocation?.latitude,
                          selectedLocation?.longitude,
                        ]}
                        icon={customIcon}
                      >
                        <Popup>
                          {selectedLocation?.street}, {selectedLocation?.city}{" "}
                          {selectedLocation?.country}
                        </Popup>
                      </Marker>
                    )}
                    {selectedLocation?.new && <ClickMarker />}
                  </MarkerClusterGroup>
                </MapContainer>
              </div>
              {selectedLocation && (
                <Fieldset legend={t("LocationInfo")} className="mt-3">
                  <LoadingOverlay visible={locationLoading} />
                  {/* <NumberInput className='mt-2' label={t('Longitude')} value={newPosition?.lng} />
                        <NumberInput className='mt-2' label={t('Latitude')} value={newPosition?.lat} /> */}
                  <TextInput
                    className="mt-2"
                    label={t("LocationName")}
                    placeholder={t("LocationName")}
                    value={selectedLocation?.name}
                    name="name"
                    onChange={handleChange}
                  />
                  <TextInput
                    className="mt-2"
                    label={t("Address")}
                    placeholder={t("Address")}
                    value={selectedLocation?.address}
                    name="address"
                    onChange={handleChange}
                  />
                  <NumberInput
                    className="mt-2"
                    label="Longitude"
                    value={selectedLocation?.longitude}
                    name="longitude"
                    disabled
                  />
                  <NumberInput
                    className="mt-2"
                    label="Latitude"
                    value={selectedLocation?.latitude}
                    name="latitude"
                    disabled
                  />
                  <Switch
                    className="mt-2"
                    checked={selectedLocation?.active}
                    onChange={(event) =>
                      setSelectedLocation({
                        ...selectedLocation,
                        active: event.currentTarget.checked,
                      })
                    }
                    label="This location is active"
                  />
                  <div
                    className="w-100 mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button size="sm" onClick={handleSave}>
                      {t("SaveChanges")}
                    </Button>
                  </div>
                </Fieldset>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default LocationsTab;
