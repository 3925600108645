import React from "react";
import {
  Button,
  Fieldset,
  InputLabel,
  LoadingOverlay,
  PasswordInput,
  SegmentedControl,
  Switch,
  Tabs,
  TextInput,
} from "@mantine/core";
import { useRecoilState } from "recoil";
import { employeeDataAtom } from "../../recoil/atoms";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { API } from "../../static";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Avatar from "react-avatar";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import moment from "moment";

const PersonalTab = () => {
  const [employeeData, setEmployeeData] = useRecoilState(employeeDataAtom);

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleSave = async (e) => {
    e.preventDefault();
    updateEmployeeMutation.mutate({ ...employeeData });
  };

  const handleChange = ({ target }) => {
    setEmployeeData({ ...employeeData, [target.name]: target.value });
  };

  const updateEmployeeMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/employee/update/${employeeData?._id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployees");
      toast.success(t("SavedSuccessfully"));
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  return (
    <Row>
      <Col md="2">
        <Avatar
          className="m-4"
          round
          size={120}
          name={`${employeeData?.firstName} ${employeeData?.lastName}`}
          src={
            employeeData?.profilePicture
              ? `https://clockitin.devworks-solutions.com/${employeeData?.profilePicture}`
              : ""
          }
        />
      </Col>
      <Col md="6">
        <LoadingOverlay visible={updateEmployeeMutation.isLoading} />
        <Switch
          className="mt-4"
          checked={employeeData?.active}
          onChange={(event) =>
            setEmployeeData({
              ...employeeData,
              active: event.currentTarget.checked,
            })
          }
          label="This employee account is active"
        />
        <TextInput
          className="mt-2"
          label="First Name"
          placeholder="First Name"
          value={employeeData?.firstName}
          onChange={handleChange}
          name="firstName"
        />
        <TextInput
          className="mt-2"
          label="Last Name"
          placeholder="Last Name"
          value={employeeData?.lastName}
          onChange={handleChange}
          name="lastName"
        />
        <TextInput
          className="mt-2"
          label="Preferred Name"
          placeholder="Preferred Name"
          value={employeeData?.preferredName}
          onChange={handleChange}
          name="preferredName"
        />
        <TextInput
          className="mt-2"
          label="Email"
          placeholder="Email"
          value={employeeData?.email}
          onChange={handleChange}
          name="email"
        />
        <TextInput
          className="mt-2"
          label="Phone number"
          placeholder="Phone number"
          value={employeeData?.phoneNumber}
          onChange={handleChange}
          name="email"
        />
        <TextInput
          className="mt-2"
          label="Address"
          placeholder="Address"
          value={employeeData?.address}
          onChange={handleChange}
          name="address"
        />
        <TextInput
          className="mt-2"
          label="Nationality"
          placeholder="Nationality"
          value={employeeData?.nationality}
          onChange={handleChange}
          name="nationality"
        />
        <div className="mt-2">
          <InputLabel>Gender</InputLabel>
          <SegmentedControl
            className="mx-4"
            value={employeeData?.type}
            onChange={(val) =>
              setEmployeeData({ ...employeeData, gender: val })
            }
            data={["Male", "Female", "Other"]}
          />
        </div>
        <div className="mt-2">
          <InputLabel>Marital status</InputLabel>
          <SegmentedControl
            className="mx-4"
            value={employeeData?.maritalStatus}
            onChange={(val) =>
              setEmployeeData({ ...employeeData, maritalStatus: val })
            }
            data={["Single", "Married"]}
          />
        </div>
        <DatePickerInput
          className="mt-2"
          label="Birth date"
          value={moment(employeeData?.birthDate)}
          onChange={(val) =>
            setEmployeeData({ ...employeeData, birthDate: val })
          }
        />
        <Fieldset className="mt-4" legend="Emergency contact">
          <TextInput
            className="mt-2"
            label="Emergency contact name"
            placeholder="Emergency contact name"
            value={employeeData?.emergencyContact?.fullName}
            onChange={({ target }) =>
              setEmployeeData({
                ...employeeData,
                emergencyContact: {
                  ...employeeData.emergencyContact,
                  fullName: target.value,
                },
              })
            }
          />
          <TextInput
            className="mt-2"
            label="Emergency contact phone number"
            placeholder="Emergency contact  phone number"
            value={employeeData?.emergencyContact?.phoneNumber}
            onChange={({ target }) =>
              setEmployeeData({
                ...employeeData,
                emergencyContact: {
                  ...employeeData.emergencyContact,
                  phoneNumber: target.value,
                },
              })
            }
          />
        </Fieldset>
        <div
          className="w-100 mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button size="sm" onClick={handleSave}>
            {t("SaveChanges")}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default PersonalTab;
