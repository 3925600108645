/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { useRecoilState } from "recoil";
import { adminAtom } from "../recoil/atoms";
import { useQuery } from "react-query";
import axios from "axios";
import { API } from "../static";

const Routers = () => {
  const [admin, setAdmin] = useRecoilState(adminAtom);

  // useEffect(() => {
  //   let abortController = new AbortController();
  //   setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
  //   console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
  //   console.disableYellowBox = true;
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);

  return (
    <BrowserRouter basename={"/"}>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={"/"} element={<PrivateRoute />}>
              {admin?.isAuthenticated ? (
                <>
                  <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                  <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                </>
              ) : (
                ""
              )}
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
            <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
