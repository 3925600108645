/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../AbstractElements";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API } from "../static";
import { Button, LoadingOverlay, PasswordInput, Switch, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ChevronDown, ChevronUp } from "react-feather";

const AdminDetail = () => {
  const { id } = useParams();
  const [adminData, setAdminData] = useState();
  const [password, setPassword] = useState();
  const [passwordPanelVisible, setPasswordPanelVisible] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getAdmin = () => {
    return useQuery(
      "getAdmin",
      async () => {
        return await axios.get(`${API}/admin-api/V1/admin/info/${id}`);
      },
      {
        onSuccess: (resp) => {
          setAdminData(resp?.data);
        },
      }
    );
  };

  const { isLoading } = getAdmin();

  const handleChange = ({ target }) => {
    setAdminData({ ...adminData, [target.name]: target.value });
  };

  const updateAdminMutation = useMutation({
    mutationFn: (data) =>
      axios.post(`${API}/admin-api/V1/admin/update/${adminData?._id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries("getAdmins");
      toast.success(t("SavedSuccessfully"));
      navigate("/administrators");
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const updateAdminPasswordMutation = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${API}/admin-api/V1/admin/update-password/${adminData?._id}`,
        {password: password}
      ),
    onSuccess: () => {
      queryClient.invalidateQueries("getAdmins");
      toast.success(t("SavedSuccessfully"));
      navigate("/administrators");
    },
    onError: () => {
      toast.error(t("ErrorOccurred"));
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    updateAdminMutation.mutate({ ...adminData });
  };

  const handleSaveNewPassword = async (e) => {
    e.preventDefault();
    updateAdminPasswordMutation.mutate({ password: password });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Edit administrator</H5>
                <span>Edit this administrator account</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                  <LoadingOverlay visible={updateAdminMutation.isLoading} />
                    <Switch
                      checked={adminData?.active}
                      onChange={(event) =>
                        setAdminData({
                          ...adminData,
                          active: event.currentTarget.checked,
                        })
                      }
                      label="This administrator is active"
                    />
                    <TextInput
                      className="mt-2"
                      label="First Name"
                      placeholder="First Name"
                      value={adminData?.firstName}
                      onChange={handleChange}
                      name="firstName"
                    />
                    <TextInput
                      className="mt-2"
                      label="Last Name"
                      placeholder="Last Name"
                      value={adminData?.lastName}
                      onChange={handleChange}
                      name="lastName"
                    />
                    <TextInput
                      className="mt-2"
                      label="Username"
                      placeholder="Username"
                      value={adminData?.username}
                      onChange={handleChange}
                      name="username"
                    />
                    <TextInput
                      className="mt-2"
                      label="Email"
                      placeholder="Email"
                      value={adminData?.email}
                      onChange={handleChange}
                      name="email"
                    />
                    <div
                      className="w-100 mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button size="sm" onClick={handleSave}>
                        {t("SaveChanges")}
                      </Button>
                    </div>
                  </Col>
                  <Col md="6" style={{ borderLeft: "1px solid lightGrey" }}>
                  <LoadingOverlay visible={updateAdminPasswordMutation.isLoading} />
                    <Button
                      variant="outline"
                      rightSection={
                        passwordPanelVisible ? (
                          <ChevronUp size={15} />
                        ) : (
                          <ChevronDown size={15} />
                        )
                      }
                      onClick={() =>
                        setPasswordPanelVisible(!passwordPanelVisible)
                      }
                    >
                      Edit password
                    </Button>
                    {passwordPanelVisible && (
                      <>
                        <PasswordInput
                          className="mt-2"
                          label="Password"
                          placeholder="Password"
                          onChange={({target}) => setPassword(target.value)}
                          name="password"
                        />
                        <div
                          className="w-100 mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            className="mt-2"
                            size="sm"
                            onClick={handleSaveNewPassword}
                          >
                            {t("SaveChanges")}
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDetail;
