import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const urlNavigationAtom = atom({
  key: "urlNavigationState",
  default: `${process.env.PUBLIC_URL}/dashboard`,
});

export const adminAtom = atom({
  key: "authState",
  default: {
    isAuthenticated: false,
    admin: {},
  },
  effects_UNSTABLE: [persistAtom],
})


export const employeeDataAtom = atom({
  key: 'employeeDataState',
  default: {},
});