import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { ToastContainer } from "react-toastify";
import { colorsTuple, createTheme, MantineProvider } from "@mantine/core";
import { ConfigProvider } from "antd";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "leaflet/dist/leaflet.css";

const theme = createTheme({
  colors: {
    primary: colorsTuple("#20c997"),
  },
  primaryColor: "primary",
});

// Create a query client
const queryClient = new QueryClient();

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <AnimationThemeProvider>
        <MantineProvider theme={theme}>
          <ConfigProvider theme={{ token: { colorPrimary: "#20c997" } }}>
            <QueryClientProvider client={queryClient}>
              <RecoilRoot>
                <Routers />
              </RecoilRoot>
            </QueryClientProvider>
          </ConfigProvider>
        </MantineProvider>
      </AnimationThemeProvider>
    </CustomizerProvider>
    <ToastContainer position="bottom-center" theme="light" />
  </div>
);

export default App;
