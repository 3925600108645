import React from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import AdminsTable from "../Components/Administrators/Datatable";

const AdministratorsList = () => {

  return (
    <>
      <Breadcrumbs
        mainTitle={"Administrators list"}
        parent={"Administrators"}
        title={"List"}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <AdminsTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdministratorsList;
