import { Briefcase, Calendar, File, HelpCircle, Lock, Pause, PlusCircle, Settings, Share, Users } from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    Items: [
      {
        title: "Attendances",
        icon: Calendar,
        type: "link",
        path: `${process.env.PUBLIC_URL}/attendances`,
        active: false,
      },
      {
        title: "Leaves",
        icon: Pause,
        type: "link",
        path: `${process.env.PUBLIC_URL}/leaves`,
        active: false,
      },
      {
        title: "Employees",
        icon: Users,
        type: "link",
        path: `${process.env.PUBLIC_URL}/employees`,
        active: false,
      },
      {
        title: "My company",
        icon: Briefcase,
        type: "link",
        path: `${process.env.PUBLIC_URL}/organization`,
        active: false,
      },
      {
        title: "Administrators",
        icon: Lock,
        type: "link",
        path: `${process.env.PUBLIC_URL}/administrators`,
        active: false,
      },
    ],
  },
  {
    menutitle: "QuickActions",
    Items: [
      { path: `${process.env.PUBLIC_URL}/employee/new`, icon: PlusCircle, type: "link", active: false, title: "CreateEmployee" },
      { path: `${process.env.PUBLIC_URL}/share`, icon: Share, type: "link", active: false, title: "Share" },

    ]
  },
  {
    menutitle: "Help",
    Items: [
      { path: `${process.env.PUBLIC_URL}/support`, icon: HelpCircle, type: "link", active: false, title: "Support" },

    ]
  }
];
