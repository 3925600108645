/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5, H6 } from "../AbstractElements";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { API } from "../static";
import {
  Button,
  LoadingOverlay,
  PasswordInput,
  Switch,
  Tabs,
  TextInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ChevronDown, ChevronUp } from "react-feather";
import { useRecoilState } from "recoil";
import { employeeDataAtom } from "../recoil/atoms";
import PersonalTab from "../Components/Employees/PersonalTab";
import WorkTab from "../Components/Employees/WorkTab";
import AttendancesTab from "../Components/Employees/AttendancesTab";
import LeavesTab from "../Components/Employees/LeavesTab";
import SchedulesTab from "../Components/Employees/SchedulesTab";
import PasswordTab from "../Components/Employees/PasswordTab";
import LeaveTypesTab from "../Components/Employees/LeaveTypesTab";

const EmployeeDetail = () => {
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useRecoilState(employeeDataAtom);
  const [password, setPassword] = useState();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getEmployee = () => {
    return useQuery(
      "getEmployee",
      async () => {
        return await axios.get(`${API}/admin-api/V1/employee/info/${id}`);
      },
      {
        onSuccess: (resp) => {
          setEmployeeData(resp?.data);
        },
      }
    );
  };

  const { isLoading } = getEmployee();

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Edit employee</H5>
                <span>Edit this employee account</span>
              </CardHeader>
              <CardBody>
                <Tabs
                  keepMounted={false}
                  defaultValue="personal"
                  styles={{ tabLabel: { fontSize: 16 } }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="personal">Personal information</Tabs.Tab>
                    <Tabs.Tab value="work">Work information</Tabs.Tab>
                    <Tabs.Tab value="attendances">Attendances</Tabs.Tab>
                    <Tabs.Tab value="leaves">Leaves</Tabs.Tab>
                    <Tabs.Tab value="leave-types">Leave Types</Tabs.Tab>
                    <Tabs.Tab value="schedules">Schedules</Tabs.Tab>
                    <Tabs.Tab value="password">Password</Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="personal">
                    <PersonalTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="work">
                    <WorkTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="attendances">
                    <AttendancesTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="leave-types">
                    <LeaveTypesTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="leaves">
                    <LeavesTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="schedules">
                    <SchedulesTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="password">
                    <PasswordTab />
                  </Tabs.Panel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeDetail;
