import React from 'react'
import { Breadcrumbs } from '../AbstractElements'
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import LeavesTable from '../Components/Leaves/LeavesTable';

const LeavesList = () => {
  return (
    <>
      <Breadcrumbs
        mainTitle={"Leaves list"}
        parent={"Leaves"}
        title={"List"}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <LeavesTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LeavesList